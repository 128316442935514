import React from "react";
import SEO from "../components/seo";

const None = ({ pageContext }) => (
	<SEO
		title={pageContext.HOME_META_TITLE}
		description={pageContext.HOME_META_DESCRIPTION}
		lang={pageContext.lang}
		keywords={["vývoj aplikací"]}
		robots={pageContext.robots}
	/>
);

export default None;
